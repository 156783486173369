<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-08-26 16:54:37
 * @LastEditors: ------
 * @LastEditTime: 2021-08-31 11:32:22
-->
<template>
  <div class="site_container">
    <div class="banner">
      <img src="@/assets/serveSite/banner.png" alt="" />
    </div>
    <main>
      <div class="title">服务站点</div>
      <div class="site_box" v-for="(item, index) in siteData" :key="index">
        <div class="line"></div>
        <div class="box_item">
          <div class="name">{{ item.name }}</div>
          <div class="address">{{ item.address }}</div>
          <div class="phone">{{ item.phone }}</div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "site",
  data() {
    return {
      siteData: [
        {
          name: "艾禹护护理站",
          address: "地址：浦东新区张杨路2590号",
          phone: "电话：5065-6698",
        },
        {
          name: "德心居护理站",
          address: "地址：浦东新区东三里桥路237-2号",
          phone: "电话：5858-1170",
        },
        {
          name: "艾悦护护理站",
          address: "地址：浦东新区海阳路452号",
          phone: "电话：5089-8978",
        },
        {
          name: "艾高护护理站",
          address: "地址：浦东新区和龙路447-3号",
          phone: "电话：5890-9055",
        },
        {
          name: "爱介护沪东为老服务中心",
          address: "地址：浦东新区五莲路430号",
          phone: "电话：5065-6698",
        },
        {
          name: "爱介护北蔡为老服务中心",
          address: "地址：浦东新区锦尊路229号",
          phone: "电话：5858-1170",
        },
      ],
    };
  },
};
</script>

<style scoped lang="less">
.site_container {
  text-align: center;
  .banner {
    img {
      width: 100%;
    }
  }
  .title {
    padding: 26px 0 10px 0;
    font-size: 22px;
    color: var(--homeColor);
    font-weight: bold;
  }
  .site_box {
    // width: 314px;
    margin: 20px 30px;
    display: flex;
    justify-content: center;
    border-radius: 7px;
    box-shadow: 0px 4px 16px rgba(105, 115, 163, 0.11);
    background-color: var(--white);
    font-size: 16px;
    position: relative;
    .box_item {
      text-align: left;
      width: 100%;
      margin-left: 36px;
      line-height: 1.8;
      padding: 20px 0;
      .name {
        font-size: 18px;
        font-weight: 600;
        color: var(--siteColor);
      }
    }
    .line {
      height: 100%;
      width: 12px;
      background-color: var(--siteColor);
      position: absolute;
      left: 0;

      border-radius: 7px 0px 7px 0px;
    }
  }
}
</style>
